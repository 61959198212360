<script setup lang="ts">
const { notifications, removeOne } = useNotificationsEx();
</script>
<template>
  <div
    class="fixed z-200 left-3 right-3 top-3 lg:top-auto lg:bottom-10 lg:right-10 ml-3 flex flex-col items-center lg:items-end gap-4 pointer-events-none"
    data-testid="notification-container"
  >
    <LayoutNotification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
      @click:close="removeOne(notification.id)"
    />
  </div>
</template>
